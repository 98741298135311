///**
//Colori BASE
//*/
//$blm-color: #d90011;
//$blm-color-light: #cb6470;
//$blm-color-xlight: #c9abae;
//$blm-color-xxlight: #eee6e7;
//
//body.light-login {
//  background: #DFE0E2;
//}
//
//.padding-0 {
//  padding: 0px;
//}
//
//.navbar {
//  background-color: $blm-color;
//}
//
//.ace-nav > li.light-blue > a {
//  background-color: $blm-color-light;
//}
//
//.btn-primary, .btn-primary.focus, .btn-primary:focus {
//  background-color: $blm-color !important;
//  border-color: $blm-color;
//}
//
//.btn-primary.focus:hover, .btn-primary:active:hover, .btn-primary:focus:active, .btn-primary:focus:hover, .btn-primary:hover, .open > .btn-primary.dropdown-toggle, .open > .btn-primary.dropdown-toggle.focus, .open > .btn-primary.dropdown-toggle:active, .open > .btn-primary.dropdown-toggle:focus, .open > .btn-primary.dropdown-toggle:hover {
//  background-color: $blm-color !important;
//  border-color: $blm-color-light;
//}
//
//.blm-color {
//  color: $blm-color;
//}
//
//.blm-background {
//  background-color: $blm-color;
//}
//
//.no-skin .nav-list > li.active > a {
//  color: $blm-color;
//}
//
//.no-skin .nav-list > li.active::after {
//  border-color: $blm-color;
//}
//
//.no-skin .nav-list li.active > a::after {
//  border-right-color: $blm-color;
//}
//
//.no-skin .nav-list > li.active::after {
//  border-color: $blm-color;
//}
//
//.page-header h1 {
//  color: $blm-color;
//}
//
//.table-header {
//  background-color: $blm-color-light;
//}
//
//.steps > li.active .step, .steps > li.active::before, .steps > li.complete .step, .steps > li.complete::before {
//  border-color: $blm-color-light;
//}
//
//.page-content {
//  padding: 8px 12px 24px;
//}
//
//.widget-header > .widget-title {
//  color: $blm-color;
//}
//
//.accordion-style1.panel-group .panel-heading .accordion-toggle {
//  color: $blm-color;
//  background-color: $blm-color-xlight;
//}
//
//.accordion-style1.panel-group .panel-heading .accordion-toggle.collapsed {
//  color: $blm-color;
//  background-color: $blm-color-xlight;
//}
//
//.accordion-style1.panel-group .panel-heading .accordion-toggle:hover {
//  color: $blm-color;
//  background-color: $blm-color-xxlight;
//}
//
//.login-box .toolbar {
//  background: $blm-color-light;
//}

.widget-header .muted.subtitle {
  color: grey;
}

.titolo_statistiche_homepage {
  min-height: 70px !important;
}

body.light-login {
  // background: url("../../images/background01.jpg") repeat scroll 0 0;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

#placement-table tr:nth-child(odd),
#components-table tr:nth-child(odd),
#kits-table tr:nth-child(odd) {
  background-color: #eee;
}